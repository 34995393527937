<template>
  <div>
    <toggleSwitch2
      :title="$t('survey.showInCalendar')"
      :help="''"
      :value="data"
      :callback="setValue"
    />
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      data: this.getValue()
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getValue() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));

      if (
        typeof data.survey === "undefined" ||
        typeof data.survey.show === "undefined"
      ) {
        return false;
      } else {
        return data.survey.show;
      }
    },
    setValue() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      this.data = !this.data;
      data.survey.show = this.data;
      this.$store.commit("business/prefetch", data);
    }
  }
};
</script>
<style lang="sass" scoped></style>
